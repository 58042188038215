






















import Vue from "vue";
import TopLoadingBar from "../components/TopLoadingBar.vue";

export default Vue.extend({
	name: "Page",
	components: {
		TopLoadingBar
	},
	data() {
		return {
			isLocalLoading: false
		};
	},
	created() {
		if (!this.getClub.name || this.getClub.name.toLowerCase() != this.$route.params.club.toLowerCase()) {
			this.$store.commit("pushLoading", {
				name: "GET_CLUB",
				message: "동아리 불러오는 중"
			});
			this.isLocalLoading = true;
			this.$store
				.dispatch("GET_CLUB", this.$route.params.club)
				.then(club => {
					this.$store.commit("clearLoading", "GET_CLUB");
					this.isLocalLoading = false;

					if (!club) this.$router.push("/");
				})
				.catch(err => {
					this.$store.commit("clearLoading", "GET_CLUB");
					this.isLocalLoading = false;

					this.$router.push("/");
				});
		}
	},
	watch: {
		$route() {
			(this.$refs.page__content as HTMLDivElement).scrollTop = 0;
		}
	},
	computed: {
		isTopLoading() {
			return this.$store.state.pageLoadingStack.length > 0;
		},
		isLoading() {
			return this.$store.state.loadingStack.length > 0;
		},
		getClub() {
			return this.$store.state.club;
		}
	}
});
